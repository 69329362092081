<template>
  <b-container class="main-content-container px-4" fluid>
    <b-row class="page-header py-4" no-gutters>
      <b-col class="text-center text-sm-left mb-4 mb-sm-0" col sm="4">
        <h3 class="page-title">File module</h3>
        <span class="text-uppercase page-subtitle">File server</span>
      </b-col>
      <b-col lg="4" class="text-center">
        <b-button-group>
          <router-link :to="{name: 'index-tuned-file'}" class="btn btn-outline-primary" exact>
            File database
          </router-link>
          <router-link :to="{name: 'file-order-crypt-bot-monitor'}" class="btn btn-outline-primary" exact>
            Server monitor
          </router-link>
        </b-button-group>
        <h3 class="temporalWarningMessage">Do not use File Server with custom price list (Price Config)</h3>
      </b-col>
      <b-col class="text-right">
        <div class="d-flex justify-content-end">
          <div>
            <b-checkbox switch size="lg" :checked="fileServerStatus" @change="switchFileServer($event)" class="mx-2">File server:
              <b-badge :variant="fileServerStatus? 'success': 'danger'">
                {{ fileServerStatus ? 'Enabled' : 'Disabled' }}
              </b-badge>
            </b-checkbox>
          </div>
          <div>
            <template v-if="$route.name !== 'index-tuned-file'">
              <b-button variant="outline-dark" @click="goBack" style="cursor: pointer">
                <i class="material-icons">arrow_back_ios</i> Go back
              </b-button>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card body-class="p-0">
      <router-view/>
    </b-card>
  </b-container>
</template>

<script>

import {get, post} from "@/services/api";
import Show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: "FileServer",
  mixins: [Show_alert_mixin],
  data() {
    return {
      fileServerStatus: false
    };
  },
  methods: {
    goBack() {
      try {
        this.$router.go(-1)
        return;
      } catch (e) {
      }
      const backRoutes = {
        'project-tuned-file': {name: 'index-tuned-file'},
        'view-tuned-file': {name: 'project-tuned-file', params: {id: localStorage.getItem('file_project_id')}},
      }
      this.$router.push(backRoutes[this.$route.name])
    },
    switchFileServer(enable) {

      post('files/switch-file-server', {enabled: enable}, true)
        .then(({data}) => {
          this.fileServerStatus = data.enabled
          this.showAlertNotification('File server status changed', 'success')
        })
    }
  },
  mounted() {
    get('files/get-file-server-status', null, true)
      .then(({data}) => {
        this.fileServerStatus = data.enabled
      })
  }
}
</script>

<style scoped>
.temporalWarningMessage{
 color: red;
 font-size: 14px;
}
</style>
